import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// icons
import Close from "@material-ui/icons/Close";

export default function Edittype(props) {
  const [showTimeArray, setTimeArray] = useState([
    "12:00 AM",
    "12:15 AM",
    "12:30 AM",
    "12:45 AM",
    "1:00 AM",
    "1:15 AM",
    "1:30 AM",
    "1:45 AM",
    "2:00 AM",
    "2:15 AM",
    "2:30 AM",
    "2:45 AM",
    "3:00 AM",
    "3:15 AM",
    "3:30 AM",
    "3:45 AM",
    "4:00 AM",
    "4:15 AM",
    "4:30 AM",
    "4:45 AM",
    "5:00 AM",
    "5:15 AM",
    "5:30 AM",
    "5:45 AM",
    "6:00 AM",
    "6:15 AM",
    "6:30 AM",
    "6:45 AM",
    "7:00 AM",
    "7:15 AM",
    "7:30 AM",
    "7:45 AM",
    "8:00 AM",
    "8:15 AM",
    "8:30 AM",
    "8:45 AM",
    "9:00 AM",
    "9:15 AM",
    "9:30 AM",
    "9:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
    "10:45 AM",
    "11:00 AM",
    "11:15 AM",
    "11:30 AM",
    "11:45 AM",
    "12:00 PM",
    "12:15 PM",
    "12:30 PM",
    "12:45 PM",
    "1:00 PM",
    "1:15 PM",
    "1:30 PM",
    "1:45 PM",
    "2:00 PM",
    "2:15 PM",
    "2:30 PM",
    "2:45 PM",
    "3:00 PM",
    "3:15 PM",
    "3:30 PM",
    "3:45 PM",
    "4:00 PM",
    "4:15 PM",
    "4:30 PM",
    "4:45 PM",
    "5:00 PM",
    "5:15 PM",
    "5:30 PM",
    "5:45 PM",
    "6:00 PM",
    "6:15 PM",
    "6:30 PM",
    "6:45 PM",
    "7:00 PM",
    "7:15 PM",
    "7:30 PM",
    "7:45 PM",
    "8:00 PM",
    "8:15 PM",
    "8:30 PM",
    "8:45 PM",
    "9:00 PM",
    "9:15 PM",
    "9:30 PM",
    "9:45 PM",
    "10:00 PM",
    "10:15 PM",
    "10:30 PM",
    "10:45 PM",
    "11:00 PM",
    "11:15 PM",
    "11:30 PM",
    "11:45 PM",
  ]);
  const [showTimeArrayData, setTimeArrayData] = useState([
    "12:00 AM",
    "12:15 AM",
    "12:30 AM",
    "12:45 AM",
    "1:00 AM",
    "1:15 AM",
    "1:30 AM",
    "1:45 AM",
    "2:00 AM",
    "2:15 AM",
    "2:30 AM",
    "2:45 AM",
    "3:00 AM",
    "3:15 AM",
    "3:30 AM",
    "3:45 AM",
    "4:00 AM",
    "4:15 AM",
    "4:30 AM",
    "4:45 AM",
    "5:00 AM",
    "5:15 AM",
    "5:30 AM",
    "5:45 AM",
    "6:00 AM",
    "6:15 AM",
    "6:30 AM",
    "6:45 AM",
    "7:00 AM",
    "7:15 AM",
    "7:30 AM",
    "7:45 AM",
    "8:00 AM",
    "8:15 AM",
    "8:30 AM",
    "8:45 AM",
    "9:00 AM",
    "9:15 AM",
    "9:30 AM",
    "9:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
    "10:45 AM",
    "11:00 AM",
    "11:15 AM",
    "11:30 AM",
    "11:45 AM",
    "12:00 PM",
    "12:15 PM",
    "12:30 PM",
    "12:45 PM",
    "1:00 PM",
    "1:15 PM",
    "1:30 PM",
    "1:45 PM",
    "2:00 PM",
    "2:15 PM",
    "2:30 PM",
    "2:45 PM",
    "3:00 PM",
    "3:15 PM",
    "3:30 PM",
    "3:45 PM",
    "4:00 PM",
    "4:15 PM",
    "4:30 PM",
    "4:45 PM",
    "5:00 PM",
    "5:15 PM",
    "5:30 PM",
    "5:45 PM",
    "6:00 PM",
    "6:15 PM",
    "6:30 PM",
    "6:45 PM",
    "7:00 PM",
    "7:15 PM",
    "7:30 PM",
    "7:45 PM",
    "8:00 PM",
    "8:15 PM",
    "8:30 PM",
    "8:45 PM",
    "9:00 PM",
    "9:15 PM",
    "9:30 PM",
    "9:45 PM",
    "10:00 PM",
    "10:15 PM",
    "10:30 PM",
    "10:45 PM",
    "11:00 PM",
    "11:15 PM",
    "11:30 PM",
    "11:45 PM",
  ]);
  const [count, setCount] = React.useState(0);
  const [showDaysValue, setDaysValue] = useState("Today");
  const [showTimeValue, setTimeValue] = useState("");
  const [showSelectedTimeValue, setSelectedTimeValue] = useState("");
  const [showTabOpenValue, setTabOpenValue] = useState("ASAP");

  // Display 7 day time information
  const now = new Date();
  const nextDay = now.setDate(now.getDate() + 1);
  const thirdDay = now.setDate(now.getDate() + 1);
  const fourthDay = now.setDate(now.getDate() + 1);
  const fifthDay = now.setDate(now.getDate() + 1);
  const sixthDay = now.setDate(now.getDate() + 1);
  const sevenDay = now.setDate(now.getDate() + 1);
  const eightDay = now.setDate(now.getDate() + 1);

  // when this component call first this function will be called
  const pageLoadFunction = (passData) => {
    const timeFilter = showTimeArray.filter((data) => {
      var date = new Date();
      var cuurentTimeValue =
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes();
      var changeTimeData = moment(data, ["h:mm A"]).format("HH:mm");
      if (passData === "Today") {
        return cuurentTimeValue <= changeTimeData;
      } else {
        return data;
      }
    });

    if (passData === "Today") {
      setTimeArray(timeFilter);
      setTimeValue(timeFilter[0]);
    } else {
      setTimeArray(showTimeArrayData);
    }
  };

  // Call function while click on When
  useEffect(() => {
    pageLoadFunction("Today");
    setCount(100);
  }, []);

  // On close button click event
  const onClickWhenItem = () => {
    document.body.classList.remove("sr-body");
    return <>{props.clickEvent(false)}</>;
  };

  // When click on Apply button from the popup
  const onClickApplyButton = () => {
    document.body.classList.remove("sr-body");
    return (
      <>
        {props.clickEvent(false)}
        {showTabOpenValue === "ASAP"
          ? props.onGetWhenItemValue("ASAP")
          : props.onGetWhenItemValue(showDaysValue + " at " + showTimeValue)}
      </>
    );
  };

  // OnChange day option selection event
  const onChangeOption = (e) => {
    if (e.target.name === "select_day") {
      setDaysValue(e.target.value);
      pageLoadFunction(e.target.value);
    } else if (e.target.name === "select_time") {
      setTimeValue(e.target.value);
    }
  };

  // onChange tabing click event
  const handleSelect = (key) => {
    setTabOpenValue(key);
  };

  return (
    <>
      <div className="absolute inset-0 bg-gray-6 z-10 pointer-events-none"></div>
      <div className="z-10 w-full flex justify-center absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
        <div className="mx-4 lg:mx-3 w-full  max-w-xl bg-white shadow-6xl rounded-3">
          <div className="p-3 md:py-4 md:pl-4 md:pr-5 w-full flex items-center justify-between shadow-4xl border-b ">
            <h6 className="text-base md:text-lg font-bold text-black-1">
              Edit Time
            </h6>
            <button
              type="button"
              className=""
              onClick={() => onClickWhenItem()}
            >
              <Close style={{ color: "#111111", fontSize: "24px" }} />
            </button>
          </div>
          <div className="mt-4 mx-4 my-4 md:m-8 sr-tabs">
            <Tabs
              defaultActiveKey="ASAP"
              transition={false}
              id="noanim-tab-example"
              className=" flex "
              onSelect={(key) => handleSelect(key)}
            >
              <Tab className="w-1/2" eventKey="ASAP" title="ASAP">
                {/* / Enter contain here in this step  */}
              </Tab>
              <Tab eventKey="Schedule" title="Schedule">
                <div className="mt-4 flex justify-between">
                  <select
                    name="select_day"
                    value={showDaysValue}
                    onChange={(e) => onChangeOption(e)}
                    className="w-4/12 h-10 md:h-12 mr-2 px-4 text-black-1 text-base font-normal appearance-none border border-gray-1  rounded-3 focus:outline-0 sr-delivery-type"
                  >
                    <option value="Today">Today</option>
                    <option value="Tomorrow">Tomorrow</option>
                    <option value={moment(thirdDay).format("dddd M/D/Y")}>
                      {moment(thirdDay).format("dddd M/D")}
                    </option>
                    <option value={moment(fourthDay).format("dddd M/D/Y")}>
                      {moment(fourthDay).format("dddd M/D")}
                    </option>
                    <option value={moment(fifthDay).format("dddd M/D/Y")}>
                      {moment(fifthDay).format("dddd M/D")}
                    </option>
                    <option value={moment(sixthDay).format("dddd M/D/Y")}>
                      {moment(sixthDay).format("dddd M/D")}
                    </option>
                    <option value={moment(sevenDay).format("dddd M/D/Y")}>
                      {moment(sevenDay).format("dddd M/D")}
                    </option>
                    <option value={moment(eightDay).format("MMM Do YYYY")}>
                      {moment(eightDay).format("dddd M/D")}
                    </option>
                  </select>

                  <select
                    name="select_time"
                    value={showTimeValue}
                    onChange={(e) => onChangeOption(e)}
                    className="w-4/12 h-10 md:h-12 ml-2 px-4 text-black-1 text-base font-normal appearance-none border border-gray-1  rounded-3 focus:outline-0 sr-delivery-type"
                  >
                    {showTimeArray.map((item, index) => (
                      <option
                        key={index}
                        value={moment(item, ["h:mm A"]).format("HH:MM:SS")}
                      >
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="p-4 bg-zinc-50 border-t shadow-5xl">
            <button
              onClick={() => onClickApplyButton()}
              className="block w-full md:w-auto ml-auto  p-2 md:px-5 md:py-3 text-base md:text-lg rounded-3 text-white font-bold bg-darkGreen transition duration-300 hover:bg-lightGreen hover:shadow-2xl"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
